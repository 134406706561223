<template>
  <article
    class="position-shortlist-card"
  >
    <div class="position-shortlist-card__container" @click="redirectToWorkflowBoard">
      <header class="position-shortlist-card__header">
        <div class="position-shortlist-card__header-container">
          <div class="position-shortlist-card__header-title">
            <h4
              class="position-shortlist-card__title"
              data-test="title"
            >
              {{ position.title }}
            </h4>
          </div>
        </div>
      </header>

      <div
        class="position-shortlist-card__details"
      >
        <ev-badge
          v-for="(contractType, index) in contractTypes"
          :key="index"
          class="position-shortlist-card__details-badge"
          data-test="contract-type-badge"
          variant="red-light"
          :title="contractType"
        />

        <ev-badge
          v-if="isRemote"
          class="position-shortlist-card__details-badge"
          data-test="remote-badge"
          variant="red-light"
          :title="$t(`${langPath}.remoteLocation`)"
        />
      </div>

      <div class="position-shortlist-card__body">
        <div class="position-shortlist-card__body-container">
          <ul class="position-shortlist-card__body-items">
            <li class="position-shortlist-card__body-item position-shortlist-card__invites-sent">
              <span class="position-shortlist-card__body-item-count">
                {{ position.shortlistCount }}
              </span>
              <p class="position-shortlist-card__body-item-description">
                {{ $t(`${langPath}.candidatesAwaitingReview`) }}
              </p>
            </li>

            <li class="position-shortlist-card__body-item position-shortlist-card__on-process">
              <span class="position-shortlist-card__body-item-count">
                {{ position.onClientProcessCount }}
              </span>
              <p class="position-shortlist-card__body-item-description">
                {{ $t(`${langPath}.candidatesInProcess`) }}
              </p>
            </li>
          </ul>
        </div>
      </div>

      <footer class="position-shortlist-card__footer">
        <div class="position-shortlist-card__footer-container">
          <div class="position-shortlist-card__footer-actions">
            <ev-button
              color="red"
              variant="secondary"
              size="small"
              data-test="see-details-button"
              @click="redirectToWorkflowBoard"
            >
              {{ $t(`${langPath}.callsToAction.seeDetails`) }}
            </ev-button>
          </div>
        </div>
      </footer>
    </div>
    <div class="position-shortlist-card__menu">
      <app-dropdown-menu data-test="menu">
        <template slot="title">
          <ev-icon name="ellipsis-v" color="gray60" />
        </template>

        <app-item-menu
          data-test="edit-position-link"
          @click="redirectToEdit"
        >
          {{ $t(`${langPath}.callsToAction.edit`) }}
        </app-item-menu>

        <app-item-menu
          data-test="archive-position-link"
          @click="openArchivePositionModal"
        >
          {{ $t(`${langPath}.callsToAction.archive`) }}
        </app-item-menu>
      </app-dropdown-menu>

      <archive-position-modal
        v-if="showArchivePositionModal"
        data-test="archive-position-modal"
        :position="position"
        @confirm="handleArchivePosition"
        @cancel="closeArchivePositionModal"
      />
    </div>
  </article>
</template>

<script>
import EvBadge from '@revelotech/everestV2/EvBadge'
import EvButton from '@revelotech/everestV2/EvButton'
import EvIcon from '@revelotech/everestV2/EvIcon'
import ArchivePositionModal from './ArchivePositionModal'

export default {
  name: 'PositionShortlistCard',
  components: {
    EvBadge,
    EvButton,
    EvIcon,
    ArchivePositionModal
  },
  props: {
    position: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath,
      showArchivePositionModal: false
    }
  },
  computed: {
    isRemote () {
      return this.position.remote || false
    },
    contractTypes () {
      return this.position.contractTypes || []
    }
  },
  methods: {
    redirectToEdit () {
      this.$router.push({
        name: 'PositionsEdit',
        params: { positionId: this.position.companiesPositionId },
        query: { redirect: window.location.href }
      })
    },
    redirectToWorkflowBoard () {
      if (this.position.shortlistCount) {
        this.$router.push({
          name: 'Shortlist',
          params: { id: this.position.atsPositionId }
        })
      } else {
        this.$router.push({
          name: 'WorkflowBoard',
          params: { positionId: this.position.atsPositionId }
        })
      }
    },
    openArchivePositionModal () {
      this.showArchivePositionModal = true
    },
    closeArchivePositionModal () {
      this.showArchivePositionModal = false
    },
    handleArchivePosition () {
      this.closeArchivePositionModal()
      this.$emit('archivePosition', this.position.atsPositionId)
    }
  }
}
</script>

<style lang="scss">
.position-shortlist-card {
  @include margin(bottom, 4);

  --card-width: 100%;
  --card-min-height: 260px;

  background-color: $white;
  display: flex;
  height: 100%;
  min-height: var(--card-min-height);
  padding: $base * 4;
  width: var(--card-width);

  @include breakpoint(md) {
    --card-width: 394px;
  }

  &__container {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    justify-content: space-between;
    padding-top: 2*$base;
    width: 100%;
  }

  &__header {
    align-items: flex-start;
    display: flex;

    &-container {
      align-items: center;
      display: flex;
      flex: 1;
    }

    &-title {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      flex-grow: 2;
      -webkit-line-clamp: 2;
      margin-right: $base;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__details {
    display: flex;
    flex-direction: row;
    margin-top: $base * 2;

    &-badge {
      @include margin(right, 2);
    }
  }

  &__body {
    margin-top: $base * 6;

    &-items {
      display: flex;
      gap: $base * 9;
    }

    &-item {
      align-items: baseline;
      display: flex;
      flex: 1;

      &-count {
        font: $tx-title-2;
        margin-right: $base * 5;
      }

      &-description {
        font: $tx-body;
      }
    }
  }

  &__footer {
    margin-top: $base * 8;
  }

  &__title {
    @extend %h6;

    align-items: center;
    cursor: pointer;
    flex: 2;
  }

  &__menu {
    ul {
      z-index: 1;
    }
  }
}
</style>
