<template>
  <section class="sort-by-criteria-select">
    <app-select
      data-test="sort-by-criteria"
      :label="$t(`${langPath}.label`)"
      name="sort-by"
      :options="options"
      :value="localSortBy"
      @input="updateSortBy"
    />
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { POSITIONS } from '@/store/namespaces'

const positionsHelper = createNamespacedHelpers(POSITIONS)

export default {
  name: 'SortByCriteriaSelect',
  data () {
    return {
      localSortBy: null,
      langPath: __langpath
    }
  },
  computed: {
    ...positionsHelper.mapState(['sortBy']),
    options () {
      return [
        {
          value: 'updated_at',
          label: this.$t(`${this.langPath}.labels.updatedAt`)
        },
        {
          value: 'applicant_updated_at',
          label: this.$t(`${this.langPath}.labels.applicantUpdatedAt`)
        },
        { value: 'title', label: this.$t(`${this.langPath}.labels.title`) }
      ]
    }
  },
  created () {
    this.localSortBy = this.sortBy || 'updated_at'
  },
  methods: {
    ...positionsHelper.mapActions(['updateSortByCriteria']),
    updateSortBy (newValue) {
      this.updateSortByCriteria(newValue)
      this.$emit('new-sort-criteria', newValue)
    }
  }
}
</script>
